var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ReportEntityDetails__Component" },
    [
      _c("FieldInlineSelect", {
        attrs: {
          label: "סוג",
          readOnly: _vm.readOnly,
          filterable: false,
          clearable: true,
          options: [
            { Value: 2, Text: "פרטי" },
            { Value: 1, Text: "מסחרי" }
          ],
          required: "true",
          reduce: function(item) {
            return item
          }
        },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.vehicleType,
          callback: function($$v) {
            _vm.vehicleType = $$v
          },
          expression: "vehicleType"
        }
      }),
      _c("FieldInlineSelect", {
        attrs: {
          label: "יצרן",
          readOnly: _vm.readOnly,
          filterable: true,
          clearable: true,
          optionsAsync: _vm.getManufacturers,
          "null-is-value": true,
          reduce: function(item) {
            return item
          }
        },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.manufacturer,
          callback: function($$v) {
            _vm.manufacturer = $$v
          },
          expression: "manufacturer"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "דגם", readOnly: _vm.readOnly },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.Model,
          callback: function($$v) {
            _vm.$set(_vm.model, "Model", $$v)
          },
          expression: "model.Model"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "שנה", readOnly: _vm.readOnly },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.Year,
          callback: function($$v) {
            _vm.$set(_vm.model, "Year", $$v)
          },
          expression: "model.Year"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "צבע", max: 10, readOnly: _vm.readOnly },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.Color,
          callback: function($$v) {
            _vm.$set(_vm.model, "Color", $$v)
          },
          expression: "model.Color"
        }
      }),
      this.model.Ownership && "ProfitCenter" in this.model.Ownership
        ? _c(
            "div",
            [
              _c("FieldInlineText", {
                attrs: { label: "מרכז רווח", readOnly: _vm.readOnly },
                on: { onChange: _vm.onChange },
                model: {
                  value: _vm.model.Ownership.ProfitCenter,
                  callback: function($$v) {
                    _vm.$set(_vm.model.Ownership, "ProfitCenter", $$v)
                  },
                  expression: "model.Ownership.ProfitCenter"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }