<template>
  <div class="ReportEntityDetails__Component">
    <!-- <FieldInlineText
        label="לוחית רישוי"
        @onChange="onChange"
        v-model="model.PlateNumber"/> -->

    <FieldInlineSelect
      label="סוג"
      @onChange="onChange"
      :readOnly="readOnly"
      :filterable="false"
      :clearable="true"
      :options="[
        { Value: 2, Text: 'פרטי' },
        { Value: 1, Text: 'מסחרי' },
      ]"
      required="true"
      v-model="vehicleType"
      :reduce="(item) => item"
    />

    <FieldInlineSelect
      label="יצרן"
      @onChange="onChange"
      :readOnly="readOnly"
      :filterable="true"
      :clearable="true"
      :optionsAsync="getManufacturers"
      :null-is-value="true"
      v-model="manufacturer"
      :reduce="(item) => item"
    />

    <!-- <FieldInlineText
        label="בעלים"
        @onChange="onChange"
        v-model="model.Ownership"/> -->

    <FieldInlineText
      label="דגם"
      @onChange="onChange"
      :readOnly="readOnly"
      v-model="model.Model"
    />

    <FieldInlineText
      label="שנה"
      @onChange="onChange"
      :readOnly="readOnly"
      v-model="model.Year"
    />

    <FieldInlineText
      label="צבע"
      @onChange="onChange"
      :max="10"
      :readOnly="readOnly"
      v-model="model.Color"
    />
    <div v-if="this.model.Ownership && 'ProfitCenter' in this.model.Ownership">
      <FieldInlineText
        label="מרכז רווח"
        @onChange="onChange"
        :readOnly="readOnly"
        v-model="model.Ownership.ProfitCenter"
      />
    </div>
  </div>
</template>

<script>
import FieldInlineText from "@/components/Fields/FieldInlineText.vue";
import FieldInlineSelect from "@/components/Fields/FieldInlineSelect.vue";
import { mapGetters, mapMutations } from "vuex";
import ManufacturersService from "@/services/ManufacturersService";

export default {
  name: "VehicleEntityDetails",
  components: {
    FieldInlineText,
    FieldInlineSelect,
  },
  props: ["readOnly", "OnProps"],
  data() {
    return {
      model: {
        Manufacturer: null,
        Ownership: null,
        PlateNumber: null,
        Model: null,
        Year: null,
        Color: null,
        VehicleType: null,
      },
    };
  },
  computed: {
    ...mapGetters("vehicle", ["form"]),
    vehicleType: {
      get() {
        if (this.model.VehicleType) {
          return {
            Value: this.model.VehicleType.Id,
            Text: this.model.VehicleType.Name,
          };
        }
        return null;
      },
      set(value) {
        if (value) {
          this.model.VehicleType = { Id: value.Value, Name: value.Text };
        } else {
          this.model.VehicleType = null;
        }
      },
    },
    manufacturer: {
      get() {
        if (this.model.Manufacturer) {
          return {
            Value: this.model.Manufacturer.Id,
            Text: this.model.Manufacturer.Name,
          };
        }
        return null;
      },
      set(value) {
        if (value) {
          this.model.Manufacturer = { Id: value.Value, Name: value.Text };
        } else {
          this.model.Manufacturer = null;
        }
      },
    },
    changesAmount() {
      return Object.keys(this.diff(this.model, this.form)).length;
    },
  },
  created() {
    this.model = { ...this.model, ...this.form };
  },
  methods: {
    getManufacturers() {
      return ManufacturersService.get;
    },
    diff(o1, o2) {
      const diff = Object.keys(o2).reduce((diff1, key) => {
        if (o1[key] && typeof o1[key].getMonth === "function") {
          if (new Date(o1[key]).toString() === new Date(o2[key]).toString()) {
            return diff1;
          }
        }
        if (typeof o1[key] === "number") {
          if (parseInt(o1[key], 10) === parseInt(o2[key], 10)) return diff1;
        }
        if (
          o1[key] &&
          typeof o1[key] === "object" &&
          typeof o1[key].getMonth !== "function"
        ) {
          if (o1[key]?.Id === o2[key]?.Id) return diff1;
        }
        if (o1[key] === o2[key]) return diff1;
        return {
          ...diff1,
          [key]: o2[key],
        };
      }, {});

      return diff;
    },
    onChange() {
      this.$emit("onFormChange", this.changesAmount);
    },
    cancel() {
      this.model = { ...this.model, ...this.form };
    },
    save() {
      this.$emit("onFormSave", {
        model: this.model,
        cancel: this.cancel,
        setRestorePoint: this.setRestorePoint,
      });
    },
    setRestorePoint() {
      this.storeReportDetails(this.model);
    },
    refresh() {
      this.model = { ...this.model, ...this.form };
    },
    ...mapMutations("vehicle", ["storeVehicleDetails"]),
  },
  watch: {
    OnProps(val) {
      let manufacturer;
      this.getManufacturers()("").then((r) => {
        manufacturer = r.find((manufactur) =>
          manufactur.Text.includes(val.Manufacturer)
        );
        val.Manufacturer = manufacturer || this.model.Manufacturer;
        val.VehicleType = val?.VehicleType?.includes("פרטי" || "מסחרי")
          ? val.VehicleType
          : this.model.VehicleType;
        this.model = { ...val };
        this.onChange();
      });
    },
  },
};
</script>

<style>
</style>
